import React from 'react';

import { Block } from 'src/shared/enums';
import SEO from 'src/components/seo';
import {
  BlockContactHero,
  BlockCoworkersBlock,
  BlockMainHeading,
  BlockOfferLineup,
  BlockStartPageHero,
  BlockImageAndText,
  BlockCenteredText,
  BlockCaseHero,
  BlockListBlock,
  BlockTwoColumns,
  BlockCaseImageFigure,
  BlockImageMosaic,
  BlockImageFullWidth,
  BlockImageTextWidth,
  BlockProcessHero,
  BlockCaseEnd,
  BlockArticleListing,
  BlockCaseListing,
  BlockWebinarForm
} from 'src/blocks';

const BlockPage = ({ pageContext }) => {
  // @todo add types
  const page = pageContext.page;

  if (!page.Blocks || page.Blocks.length == 0) {
    return <h1>{page.Title}</h1>;
  }

  const blocks = [];

  // Identify all supported blocks and render them
  // @todo add types for all blocks
  page.Blocks.forEach((block, index) => {
    switch (block.__typename) {
      case Block.ContactHero:
        blocks.push(<BlockContactHero key={index} block={block} />);
        break;
      case Block.CoworkersBlock:
        blocks.push(<BlockCoworkersBlock key={index} block={block} />);
        break;
      case Block.MainHeading:
        blocks.push(<BlockMainHeading key={index} block={block} />);
        break;
      case Block.OfferLineup:
        blocks.push(<BlockOfferLineup key={index} block={block} />);
        break;
      case Block.StartPageHero:
        blocks.push(<BlockStartPageHero key={index} block={block} />);
        break;
      case Block.ImageAndText:
        blocks.push(<BlockImageAndText key={index} block={block} />);
        break;
      case Block.ContentBlock:
        blocks.push(<BlockCenteredText key={index} block={block} />);
        break;
      case Block.CaseHero:
        blocks.push(<BlockCaseHero key={index} block={block} />);
        break;
      case Block.ListBlock:
        blocks.push(<BlockListBlock key={index} block={block} />);
        break;
      case Block.TwoColumns:
        blocks.push(<BlockTwoColumns key={index} block={block} />);
        break;
      case Block.CaseImageFigure:
        blocks.push(<BlockCaseImageFigure key={index} block={block} />);
        break;
      case Block.ImageMosaic:
        blocks.push(<BlockImageMosaic key={index} block={block} />);
        break;
      case Block.ImageFullWidth:
        blocks.push(<BlockImageFullWidth key={index} block={block} />);
        break;
      case Block.ImageTextWidth:
        blocks.push(<BlockImageTextWidth key={index} block={block} />);
        break;
      case Block.ProcessHero:
        blocks.push(<BlockProcessHero key={index} block={block} />);
        break;
      case Block.CaseEnd:
        blocks.push(<BlockCaseEnd key={index} block={block} />);
        break;
      case Block.ArticleListing:
        blocks.push(<BlockArticleListing key={index} block={block} />);
        break;
      case Block.CaseListing:
        blocks.push(<BlockCaseListing key={index} block={block} />);
        break;
      case Block.WebinarForm:
        blocks.push(<BlockWebinarForm key={index} block={block} />);
        break;
    }
  });

  return (
    <div>
      <SEO
        title={page.PageTitle}
        description={page.ShortDescription}
        image={page.Image ? page.Image.imageFile.publicURL : null}
        slug={page.Slug}
        type={page.__typename}
      />

      {blocks}
    </div>
  );
};

export default BlockPage;
