import React, { Component, createRef } from 'react';
import styled from 'styled-components';
import Fade from 'react-reveal/Fade';

import { BlockImageFullWidth as ImageFullWidthType } from '../shared/interfaces';

import { Color } from 'src/config/variables';
import { WrapMax } from 'src/components/shared/wraps';
import Image from 'src/components/Image';

interface Props {
  block: ImageFullWidthType;
}

export const BlockImageFullWidth = (props: Props) => (
  <WrapMax data-color={Color.white}>
    {props.block.Image && (
      <Image filename={props.block.Image.imageFile.relativePath} alt={props.block.Image.alternativeText} />
    )}
  </WrapMax>
);