import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import Fade from 'react-reveal/Fade';
import parser from 'html-react-parser';

import { WrapMax } from 'src/components/shared/wraps';
import { Button } from 'src/components/shared';
import Image from 'src/components/Image';

import { Color, ScalingSize, DynamicMargin } from 'src/config/variables';

import { BlockImageAndText as ImageAndTextType } from 'src/shared/interfaces';

interface Props {
  block: ImageAndTextType;
  rtl?: Boolean;
}

interface State {
  hover: Boolean;
}

export class BlockImageAndText extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      hover: false,
    };
  }

  render() {
    const { block } = this.props;
    let { rtl } = this.props;

    rtl = block.ImagePosition === 'Left' ? false : true;

    return (
      <StyledWrap data-color={Color.white}>
        <Fade bottom distance={'10%'} fraction={0.2}>
          <Article rtl={rtl} data-color={Color.white}>
            {block.Image && (
              <Figure rtl={rtl} onMouseEnter={this.mouseEnter.bind(this)} onMouseLeave={this.mouseLeave.bind(this)}>
                <Image filename={block.Image.imageFile.relativePath} alt={block.Image.alternativeText} />
              </Figure>
            )}
            <Content>
              <Title>{block.Heading}</Title>
              <p>{parser(block.Content)}</p>
              {block.Link && !Array.isArray(block.Link) && (
                <Button
                  to={block.Link.URL}
                  theme="ghost"
                  size="small"
                  text={block.Link.Title}
                  icon="ArrowRight"
                  onMouseEnter={this.mouseEnter.bind(this)}
                  onMouseLeave={this.mouseLeave.bind(this)}
                />
              )}
              {block.Link && Array.isArray(block.Link) && (
                <CtaList>
                  {block.Link.map((cta, index) => (
                    <li key={`button_${index}`}>
                      <Button
                        to={cta.URL}
                        theme="ghost"
                        size="small"
                        text={cta.Title}
                        icon="ArrowRight"
                        onMouseEnter={this.mouseEnter.bind(this)}
                        onMouseLeave={this.mouseLeave.bind(this)}
                      />
                    </li>
                  ))}
                </CtaList>
              )}
            </Content>
          </Article>
        </Fade>
        {block.Button && <StyledFade bottom distance={'10%'} fraction={0.2} as={Button} href={block.Button.URL} text={block.Button.Title} icon="ArrowRight" />}
      </StyledWrap>
    );
  }

  mouseEnter(ev) {
    this.setState({
      hover: true,
    });
  }

  mouseLeave(ev) {
    this.setState({
      hover: false,
    });
  }
}

const StyledWrap = styled(WrapMax)`
  &:last-child {
    margin-bottom: 4em;
  }
`;

const Title = styled.h2`
  color: ${Color.slate};
  margin-top: 0;
  ${ScalingSize('font-size', 24, 48)}
  line-height: 1.1;
  margin-bottom: 0.571428571em;
`;

const Article = styled.article`
  display: grid;
  grid-gap: 2em 5vw;
  align-items: center;
  ${DynamicMargin};

  @media screen and (min-width: 1680px) {
    grid-gap: 2em 5.25em;
  }

  ${props =>
    props.rtl
      ? `
  @media screen and (min-width: 1024px) {
    grid-template-columns: 4fr 6fr;
  }`
      : `
  @media screen and (min-width: 1024px) {
    grid-template-columns: 6fr 4fr;
  }
  `}

  p {
    color: ${Color.slate3};
    margin-top: 0;
  }
`;

const TransitionHover = css`
  transition: transform 0.325s cubic-bezier(0.51, -0.31, 0.57, 1.39), clip-path 0.325s cubic-bezier(0.51, -0.31, 0.57, 1.39), opacity 0s;
`;

const TransitionDefault = css`
  transition: transform 0.325s cubic-bezier(0.51, -0.31, 0.57, 1.39), clip-path 0.325s cubic-bezier(0.51, -0.31, 0.57, 1.39), opacity 0s 0.325s;
`;

const Figure = styled.figure`
  position: relative;
  ${TransitionDefault}
  margin: 0;
  ${props =>
    props.rtl &&
    `
  @media screen and (min-width: 1024px) {
    order: 2;
  }`}

  &:before {
    ${TransitionDefault}
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: '';
    background-color: ${Color.citrus};
    clip-path: inset(1em 1em 1em 1em);
    pointer-events: none;
    opacity: 0;
  }

  &.hover {
    &:before {
      ${TransitionHover}
      clip-path: inset(0 0 0 0);
      transform: translate(-1em, 1em);
      opacity: 1;
    }

    img {
      clip-path: inset(0 0 0 0);
    }
  }
`;

const CtaList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    padding: 0.25em 0;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 1em;
  padding-bottom: 1em;
`;

const StyledImage = styled(Image)`
  position: relative;
  display: block;
  ${TransitionDefault}
  margin: auto;
  clip-path: inset(1em 1em 1em 1em);
  width: 100%;
`;

const StyledFade = styled(Fade)`
  margin: 1em 0 0;
  justify-self: start;

  @media screen and (min-width: 860px) {
    justify-self: center;
    margin-top: 0;
    grid-column: 1 / 2;
  }
`;
