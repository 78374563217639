import React, { Component, createRef } from 'react';
import styled from 'styled-components';

import TextInput from 'src/components/shared/TextInput';
import { WrapSmall, WrapSmallWidth } from 'src/components/shared/wraps';
import { Button, Checkbox } from 'src/components/shared';
import { Color, ScalingSize, DynamicPadding2x } from '../config/variables';

interface IState {
  email: string;
  name: string;
  surname: string;
  office: string;
  mailingList: boolean;
  webinarTermsAccepted: boolean;
}
export class BlockWebinarForm extends Component<{}, IState> {
  private formRef = createRef<HTMLFormElement>();

  constructor(props: any) {
    super(props);

    this.state = {
      email: '',
      name: '',
      surname: '',
      office: '',
      webinarTermsAccepted: false,
      mailingList: false,
    };
  }

  render() {
    const { email, name, surname, office, webinarTermsAccepted, mailingList } = this.state;

    return (
      <Outer id="webinar-signup">
        <form
          ref={this.formRef}
          action="https://osynlig.us2.list-manage.com/subscribe/post?u=51f766054e084b015dd7f0252&amp;id=c233f4182b"
          method="post"
          id="mc-embedded-subscribe-form"
          name="mc-embedded-subscribe-form"
          target="_blank"
          rel="noopener"
          onSubmit={this.onSubmit.bind(this)}
        >
          <Inner>
            <Heading>Anmäl dig här</Heading>
            <div style={{ position: 'absolute', left: '-5000px' }} aria-hidden="true">
              <input type="text" name="b_51f766054e084b015dd7f0252_c233f4182b" tabIndex={-1} defaultValue="" />
            </div>
            <StyledTextInput
              onChange={this.onNameChange.bind(this)}
              as={TextInput}
              icon="User"
              type="text"
              name="FNAME"
              placeholder="Förnamn"
              required
              value={name}
            />
            <StyledTextInput
              onChange={this.onSurnameChange.bind(this)}
              as={TextInput}
              icon="User"
              type="text"
              name="LNAME"
              placeholder="Efternamn"
              required
              value={surname}
            />
            <StyledTextInput
              onChange={this.onEmailChange.bind(this)}
              as={TextInput}
              icon="Mail"
              type="email"
              name="EMAIL"
              placeholder="E-post"
              required
              value={email}
            />
            <StyledTextInput
              onChange={this.onOfficeChange.bind(this)}
              as={TextInput}
              icon="Home"
              type="text"
              name="OFFICE"
              placeholder="Företag"
              value={office}
            />
            <StyledCheckbox
              onChange={this.onMailingListChange.bind(this)}
              checked={mailingList}
              as={Checkbox}
              id="mce-group[69118]-69118-0"
              name="group[69118][1]"
              text={'Ja tack! Jag vill ha Osynligs nyhetsbrev'}
            />
            <StyledCheckbox
              onChange={this.onWebinarTermsChange.bind(this)}
              checked={webinarTermsAccepted}
              as={Checkbox}
              id="webinarTerms"
              text={'Jag godkänner att ta emot utskick i enighet med <a href="/integritetspolicy">integritetspolicyn</a>'}
              required
            />
            <StyledButton as={Button} text="Skicka anmälan" />
          </Inner>
        </form>
      </Outer>
    );
  }

  onNameChange(ev) {
    this.setState({
      name: ev.target.value,
    });
  }

  onSurnameChange(ev) {
    this.setState({
      surname: ev.target.value,
    });
  }

  onEmailChange(ev) {
    this.setState({
      email: ev.target.value,
    });
  }

  onOfficeChange(ev) {
    this.setState({
      office: ev.target.value,
    });
  }

  onWebinarTermsChange(ev) {
    this.setState({
      webinarTermsAccepted: ev.target.checked,
    });
  }

  onMailingListChange(ev) {
    this.setState({
      mailingList: ev.target.checked,
    });
  }

  onSubmit(ev) {
    this.formRef.current.submit();

    setTimeout(() => {
      this.setState({
        email: '',
        name: '',
        surname: '',
        office: '',
        mailingList: false,
        webinarTermsAccepted: false,
      });
    }, 500);
  }
}

const Outer = styled.section`
  background-color: ${Color.cloud4};
  ${DynamicPadding2x}
  position: relative;
`;

const Inner = styled(WrapSmall)`
  display: grid;
  justify-content: space-between;
  align-items: center;
  grid-template-columns: 100%;
  grid-gap: 1.5em 1.5em;

  @media screen and (min-width: 960px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const Heading = styled.h2`
  text-align: left;
  justify-self: center;
  align-self: center;
  margin: 0;
  ${ScalingSize('font-size', 24, 48)};
  line-height: 1.25;
  letter-spacing: -0.02em;
  grid-column: 1 / -1;
  width: 100%;
`;

const StyledTextInput = styled.div`
  box-shadow: inset 0 0 0 2px ${Color.slate};
`;

const StyledCheckbox = styled.div`
  width: 100%;
  margin: 0 auto;

  @media screen and (min-width: 480px) and (max-width: 767px) {
    margin-right: 0;
  }
`;

const StyledButton = styled.div`
  align-self: center;
  justify-self: center;

  @media screen and (min-width: 960px) {
    justify-self: end;
    grid-column: 2;
  }
`;
