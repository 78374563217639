import React from 'react';
import styled from 'styled-components';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import { StaticQuery, graphql } from 'gatsby';
import { Color } from 'src/config/variables';
import { Button, Paragraph } from 'src/components/shared';
import { WrapMax } from 'src/components/shared/wraps';
import Image from 'src/components/Image';

const Articles = ({ data }) => (
  <Outer>
    {data.cms.articles.map((article, i) => (
      <Article key={`post_${i}`} as={AniLink} to={`/artiklar/${article.Slug}`} bg={Color.citrus5} cover direction="left">
        <ArticleImageWrap>{article.Image && <Image filename={article.Image.imageFile.relativePath} alt={article.Image.alternativeText} />}</ArticleImageWrap>
        <ArticleTitle>{article.Title}</ArticleTitle>
        <ArticleContent>
          <Paragraph article>{article.ShortDescription}</Paragraph>
          {article.CTA && article.CTA.length > 0 ? (
            article.CTA.map(cta => <Button to={`/artiklar/${article.Slug}/`} theme="ghost" icon="ArrowRight" text={cta.Heading} />)
          ) : (
            <Button theme="ghost" icon="ArrowRight" text={'Läs mer'} />
          )}
        </ArticleContent>
      </Article>
    ))}
  </Outer>
);

export const BlockArticleListing = props => {
  return (
    <StaticQuery
      query={graphql`
        query {
          cms {
            articles(sort: "created_at:desc") {
              id
              Slug
              Title
              ShortDescription
              Image {
                url
                alternativeText
                imageFile {
                  relativePath
                }
              }
              Teaser {
                Heading
                Text
              }
            }
          }
        }
      `}
      render={data => <Articles data={data} {...props} />}
    />
  );
};

const Outer = styled(WrapMax)`
  display: grid;
  grid-gap: 3.5em;
  margin-bottom: 6rem;

  @media screen and (min-width: 640px) {
    grid-template-columns: repeat(auto-fit, minmax(31.875em, 1fr));
    grid-gap: 6.111111111vw;
  }
`;

const Article = styled.div`
  color: ${Color.slate};
  display: grid;
  min-height: 100px;
  grid-template-areas:
    'title image'
    'content content';
  grid-template-columns: auto 4em;
  grid-gap: 1.5em;
  align-items: center;
  margin-top: 3em;
  margin-bottom: -3em;

  @media screen and (min-width: 640px) {
    grid-template-areas:
      'image .'
      'image title'
      'image content'
      'image .';
    grid-template-columns: ${props => (props.single ? '8.888888889vw' : '12.777777778vw')} 356fr;
    grid-gap: 0 2.222222222vw;
  }
  @media screen and (min-width: 1680px) {
    grid-template-columns: ${props => (props.single ? '9.33em' : '13.42em')} 356fr;
    grid-gap: 0 2.333333333em;
  }
`;

const ArticleImageWrap = styled.div`
  width: 4em;
  height: 4em;
  grid-area: image;

  @media screen and (min-width: 640px) {
    grid-row: image-start / image-end;
    width: 100%;
    height: 0;
    padding-bottom: 100%;
  }

  img {
    width: 100%;
    border-radius: 90em;
  }
`;

const ArticleContent = styled.div`
  grid-column: content-start / content-end;
  grid-area: content;
  ${props =>
    props.single &&
    `
    max-width: 68.076923077%;
  `};
`;

const ArticleTitle = styled.h5`
  font-size: 1.5em;
  line-height: 2rem;
  letter-spacing: -0.02em;
  margin: 0;
  color: ${Color.slate};

  @media screen and (min-width: 640px) {
    font-size: 1.25em;
    margin-bottom: 0.5rem;
  }
`;
