import React, { Component } from 'react';
import styled from 'styled-components';
import Fade from 'react-reveal/Fade';
import { textCleanup } from 'src/shared/utils';
import { BlockMainHeading as BlockMainHeadingType } from '../shared/interfaces';
import { WrapMax } from 'src/components/shared/wraps';
import { Color, ScalingSize } from '../config/variables';
import { Heading1 } from 'src/components/shared';
interface Props {
  block: BlockMainHeadingType;
}

export const BlockMainHeading = (props: Props) => (
  <WrapMax>
    <Fade bottom distance={'10%'} fraction={0.2}>
      <Heading1 primary center>
        {textCleanup(props.block.Text)}
      </Heading1>
    </Fade>
  </WrapMax>
);
