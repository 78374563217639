import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import parser from 'html-react-parser';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import { Wrap, WrapMax } from './shared/wraps';
import { Button } from './shared';
import { Color, ScalingSize, DynamicMargin } from '../config/variables';

import { BlockImageAndText as BlockImageAndTextType } from '../shared/interfaces';

import Image from './Image';

import Fade from 'react-reveal/Fade';

interface Props {
  data: BlockImageAndTextType;
}

interface State {
  hover: Boolean;
}

export default class CaseItem extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      hover: false,
    };
  }

  render() {
    const { data } = this.props;
    const { hover } = this.state;

    return (
      <StyledWrap data-color={Color.white}>
        <Fade bottom distance={'10%'} fraction={0.2}>
          <Article data-color={Color.white}>
            {data.Image && (
              <Figure className={[hover && 'hover']} onMouseEnter={this.mouseEnter.bind(this)} onMouseLeave={this.mouseLeave.bind(this)}>
                <AniLink to={`/case/${data.Slug}/`} bg={Color.citrus5} cover direction="left">
                  <Image filename={data.Image.imageFile.relativePath} alt={data.Image.alternativeText} />
                </AniLink>
              </Figure>
            )}
            <Content>
              <Title>
                <LinkedText to={`/case/${data.Slug}/`} bg={Color.citrus5} cover direction="left">
                  {data.Title}
                </LinkedText>
              </Title>
              <p>{data.ShortDescription}</p>
              <Button
                to={`/case/${data.Slug}/`}
                theme="ghost"
                size="small"
                text={`Case: ${data.Title}`}
                icon="ArrowRight"
                onMouseEnter={this.mouseEnter.bind(this)}
                onMouseLeave={this.mouseLeave.bind(this)}
              />
            </Content>
          </Article>
        </Fade>
      </StyledWrap>
    );
  }

  mouseEnter(ev) {
    this.setState({
      hover: true,
    });
  }

  mouseLeave(ev) {
    this.setState({
      hover: false,
    });
  }
}

const StyledWrap = styled(WrapMax)`
  &:last-child {
    margin-bottom: 4em;
  }
`;

const Title = styled.h2`
  color: ${Color.slate};
  margin-top: 0;
  ${ScalingSize('font-size', 24, 48)}
  line-height: 1.1;
  margin-bottom: 0.571428571em;
`;

const Article = styled.article`
  display: grid;
  grid-gap: 2em 5vw;
  align-items: center;
  ${DynamicMargin};

  @media screen and (min-width: 1680px) {
    grid-gap: 2em 5.25em;
  }

  ${props =>
    props.rtl
      ? `
  @media screen and (min-width: 1024px) {
    grid-template-columns: 4fr 6fr;
  }`
      : `
  @media screen and (min-width: 1024px) {
    grid-template-columns: 6fr 4fr;
  }
  `}

  p {
    color: ${Color.slate3};
    margin-top: 0;
  }
`;

const TransitionHover = css`
  transition: transform 0.325s cubic-bezier(0.51, -0.31, 0.57, 1.39), clip-path 0.325s cubic-bezier(0.51, -0.31, 0.57, 1.39), opacity 0s !important;
`;

const TransitionDefault = css`
  transition: transform 0.325s cubic-bezier(0.51, -0.31, 0.57, 1.39), clip-path 0.325s cubic-bezier(0.51, -0.31, 0.57, 1.39), opacity 0s 0.325s !important;
`;

const Figure = styled.figure`
  position: relative;
  ${TransitionDefault}
  margin: 0;
  ${props =>
    props.rtl &&
    `
  @media screen and (min-width: 1024px) {
    order: 2;
  }`}

  &:before {
    ${TransitionDefault}
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: '';
    background-color: ${Color.citrus};
    clip-path: inset(1em 1em 1em 1em);
    pointer-events: none;
    opacity: 0;
  }

  img {
    ${TransitionDefault}
    position: relative;
    display: block;
    margin: auto;
    clip-path: inset(1em 1em 1em 1em);
    width: 100%;
  }

  &.hover {
    &:before {
      ${TransitionHover}
      clip-path: inset(0 0 0 0);
      transform: translate(-1em, 1em);
      opacity: 1;
    }

    img {
      ${TransitionHover}
      clip-path: inset(0 0 0 0);
    }
  }
`;

const CtaList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    padding: 0.25em 0;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 1em;
  padding-bottom: 1em;
`;

const StyledImage = styled(Image)`
  position: relative;
  display: block;
  ${TransitionDefault}
  margin: auto;
  clip-path: inset(1em 1em 1em 1em);
  width: 100%;
`;

const LinkedText = styled(AniLink)`
  color: ${Color.black};
`;
