import React, { Component, createRef } from 'react';
import styled from 'styled-components';
import Fade from 'react-reveal/Fade';

import { BlockImageTextWidth as ImageTextWidthType } from '../shared/interfaces';

import { Color } from 'src/config/variables';
import { WrapXs } from 'src/components/shared/wraps';
import Image from 'src/components/Image';

interface Props {
  block: ImageTextWidthType;
}

const Wrap = styled(WrapXs)`
  margin: 0 auto;

  img {
    width: auto;
    height: auto;
    max-widht: 100%;
  }

  @media screen and (min-width: 978px) {
    margin: 5rem auto;
  }
`;

export const BlockImageTextWidth = (props: Props) => (
  <Wrap data-color={Color.white}>
    {props.block.Image && (
      <Image filename={props.block.Image.imageFile.relativePath} alt={props.block.Image.alternativeText} />
    )}
  </Wrap>
);