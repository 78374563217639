import React, { Component, createRef } from 'react';
import { StaticQuery, graphql } from 'gatsby';

import CaseItem from 'src/components/CaseItem';

const Cases = ({ data }) => (
  <div>
    {data.cms.cases.map((item, i) => (
      <CaseItem key={`caseItem${i}`} data={item} />
    ))}
  </div>
);

export const BlockCaseListing = props => (
  <StaticQuery
    query={graphql`
      query {
        cms {
          cases(sort: "created_at:desc") {
            id
            Slug
            Title
            ShortDescription
            Image {
              url
              alternativeText
              imageFile {
                relativePath
              }
            }
            Teaser {
              Heading
              Text
              Image {
                url
                alternativeText
                imageFile {
                  relativePath
                }
              }
            }
          }
        }
      }
    `}
    render={data => <Cases data={data} />}
  />
);
