export enum Block {
  ContactHero = 'CMS_ComponentBlockContactHero',
  ContentBlock = 'CMS_ComponentBlockContentBlock',
  CoworkersBlock = 'CMS_ComponentBlockCoworkersBlock',
  EmployeesBlock = 'CMS_ComponentBlockEmployeesBlock',
  ListBlock = 'CMS_ComponentBlockListBlock',
  MainHeading = 'CMS_ComponentBlockMainHeading',
  OfferLineup = 'CMS_ComponentBlockOfferLineup',
  StartPageHero = 'CMS_ComponentBlockStartPageHero',
  ImageAndText = 'CMS_ComponentBlockImageAndText',
  CenteredText = 'CMS_ComponentBlockContentBlock',
  CaseHero = 'CMS_ComponentBlockCaseHero',
  List = 'CMS_ComponentBlockList',
  TwoColumns = 'CMS_ComponentBlockTwoColumns',
  CaseImageFigure = 'CMS_ComponentBlockCaseImageFigure',
  ImageMosaic = 'CMS_ComponentBlockImageMosaic',
  ImageFullWidth = 'CMS_ComponentBlockImageFullWidth',
  ImageTextWidth = 'CMS_ComponentBlockImageTextWidth',
  ProcessHero = 'CMS_ComponentBlockProcessHero',
  CaseEnd = 'CMS_ComponentBlockCaseEnd',
  ArticleListing = 'CMS_ComponentBlockArticleListing',
  CaseListing = 'CMS_ComponentBlockCaseListing',
  WebinarForm = 'CMS_ComponentBlockWebinarForm',
}