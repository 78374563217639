import React, { Component, createRef } from 'react';
import styled from 'styled-components';
import Fade from 'react-reveal/Fade';

import { ListBlock as ListBlockType } from 'src/shared/interfaces';
import { WrapMax } from 'src/components/shared/wraps';
import { Heading3 } from 'src/components/shared';
import { Color, FontOsynlig, ScalingSize } from 'src/config/variables';

interface Props {
  block: ListBlockType;
}

export const BlockListBlock = (props: Props) => (
  <WrapMax>
    <OrderedList>
      {props.block.List.map((item, index) => (
        <Fade bottom distance={'10%'} fraction={0.2} key={`listItem_${index}`}>
          <ListItem>
            <div>
              <Heading3 list>{item.Heading}</Heading3>
              {item.Content}
            </div>
          </ListItem>
        </Fade>
      ))}
    </OrderedList>
  </WrapMax>
);

const OrderedList = styled.ol`
  list-style: none;
  max-width: 60em;
  counter-reset: list;
  margin-right: auto;
  margin-left: auto;
  padding: 0;
  ${ScalingSize('margin-top', 32, 80)}
  ${ScalingSize('margin-bottom', 32, 80)}
`;

const ListItem = styled.li`
  display: grid;
  grid-template-columns: 100%;
  grid-gap: 1em 5em;
  align-items: start;
  ${ScalingSize('margin-bottom', 16, 40)};
  font-size: 1em;
  line-height: 1.75;
  margin: 1em 0 2em;
  color: ${Color.slate3};

  @media screen and (min-width: 680px) {
    grid-template-columns: 13.888888889vw auto;
  }

  &:before {
    counter-increment: list;
    content: counters(list, '.', decimal-leading-zero);
    color: ${Color.slate4};
    ${FontOsynlig};
    ${ScalingSize('font-size', 40, 128, 1.430555556)};
    border-bottom: 0.375rem solid ${Color.citrus};
    width: fit-content;
    line-height: 1;
    padding-bottom: 0.7rem;

    @media screen and (min-width: 680px) {
      margin-bottom: 1.5rem;
      text-align: right;
      margin-left: auto;
    }
  }

  p {
    font-size: 1em;
    line-height: 1.6;
    margin: 1em 0 2em;
    color: ${Color.slate3};
  }
`;
