import React, { Component, createRef } from 'react';
import styled from 'styled-components';
import Fade from 'react-reveal/Fade';

import { BlockImageMosaic as ImageMosaicType } from '../shared/interfaces';

import Image from 'src/components/Image';
import { WrapMax } from 'src/components/shared/wraps';
import { Color, ScalingSize } from 'src/config/variables';

interface Props {
  block: ImageMosaicType;
}

export const BlockImageMosaic = (props: Props) => (
  <WrapMax data-color={Color.white}>
    <Fade bottom distance={'10%'} fraction={0.2}>
      <Outer>
        {props.block.Images.map((item, index) => (
          <ImageWrap key={`mosaic_${index}`}>
            {item.imageFile && (
              <Image filename={item.imageFile.relativePath} alt={item.caption} />
            )}
          </ImageWrap>
        ))}
      </Outer>
    </Fade>
  </WrapMax>
);


const Outer = styled.figure`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  ${ScalingSize('grid-gap', 16, 24)};
  margin: 0;
  ${ScalingSize('margin-top', 48, 112)};
  ${ScalingSize('margin-bottom', 48, 112)};
`;

const ImageWrap = styled.div`
  grid-column: 1;
  grid-row: 2;
  
  &:first-child {
    margin-top: 2.96875vw;
    grid-column: 1;
    grid-row: 1;
  }
  
  &:last-child {
    grid-column: 2;
    grid-row: 1 / 3;
    height: 100%;
    display: flex;
    justify-content: stretch;
    align-items: stretch;
    
    .gatsby-image-wrapper {
      flex: 0 0 100%;
    }
  }

`;
