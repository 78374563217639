import React, { Component, createRef } from 'react';
import styled from 'styled-components';
import Fade from 'react-reveal/Fade';

import { TwoColumns as TwoColumnsType } from 'src/shared/interfaces';
import { Color } from 'src/config/variables';
import { WrapMax } from 'src/components/shared/wraps';


interface Props {
  block: TwoColumnsType;
}

export const BlockTwoColumns = (props: Props) => (
  <WrapMax>
    <Row>
      <Column>
        {props.block.Column1}
      </Column>
      <Column>
        {props.block.Column2}
      </Column>
    </Row>
  </WrapMax>
  );


  const Row = styled.div`
    display: grid;
    grid-gap: 1em;
    max-width: 61.625em;
    justify-self: center;
    align-items: start;
    margin: 0 auto;

    @media screen and (min-width: 860px) {
      grid-gap: 4em 3em;
      grid-template-columns: 1fr 1fr;
    }
  `;

  const Column = styled.p`
    margin: 0;
    color: ${Color.slate3};
    line-height: 1.75;

    @media screen and (min-width: 860px) {
      font-size: 1em;
    }
  `;
