import React from 'react';
import styled from 'styled-components';
import Fade from 'react-reveal/Fade';

import { BlockContactHero as BlockContactHeroType } from '../shared/interfaces';
import { WrapLarge } from 'src/components/shared/wraps';
import { Markdown, Heading1 } from 'src/components/shared';

interface Props {
  block: BlockContactHeroType;
}

export const BlockContactHero = (props: Props) => (
  <div>
    <WrapLarge>
      <Fade bottom distance={'10%'} fraction={0.2}>
        <Heading1 primary>{props.block.Heading}</Heading1>
      </Fade>
    </WrapLarge>

    <Contacts data-color="#fff">
      <Fade bottom distance={'10%'} fraction={0.2}>
        <address>
          <Markdown content={props.block.Column1} />
        </address>
      </Fade>

      <Fade bottom distance={'10%'} fraction={0.2}>
        <address>
          <Markdown content={props.block.Column2} />
        </address>
      </Fade>

      <Fade bottom distance={'10%'} fraction={0.2}>
        <address>
          <Markdown content={props.block.Column3} />
        </address>
      </Fade>
    </Contacts>
  </div>
);

const Contacts = styled(WrapLarge)`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 3.611111111vw 7.222222222vw;
  margin-bottom: 3.611111111vw;

  @media screen and (min-width: 978px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;
