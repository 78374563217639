import React, { Component, createRef } from 'react';
import styled from 'styled-components';
import Fade from 'react-reveal/Fade';

import Image from 'src/components/Image';

import { CaseImageFigure as CaseImageFigureType } from 'src/shared/interfaces';

import { Color, ScalingSize } from 'src/config/variables';
import { Page, Button, CaseExcerpt } from 'src/components/shared';
import { Wrap, WrapMedium, WrapXs, WrapXsWidth, WrapMax } from 'src/components/shared/wraps';
import { Markdown } from 'src/components/shared';

interface Props {
  block: CaseImageFigureType;
}

export const BlockCaseImageFigure = (props: Props) => {
  return (
    <KeyFigure>
      <KeyFigureImage>
        <Image filename={props.block.Image.imageFile.relativePath} alt={props.block.Image.alternativeText} />
      </KeyFigureImage>
      <Markdown indented={true} content={props.block.Content} />
      <KeyFigureFigure>
        <div>
          <Heading2>{props.block.Figure}</Heading2>
          <Paragraph>{props.block.FigureDescription}</Paragraph>
        </div>
      </KeyFigureFigure>
    </KeyFigure>
  );
};

const Ellipse = (color: string) =>
  `data:image/svg+xml;utf8,${encodeURIComponent(
    `<svg viewBox="0 0 560 560" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M560 280C560 434.64 434.64 560 280 560C125.36 560 0 434.64 0 280C0 125.36 125.36 0 280 0C434.64 0 560 125.36 560 280ZM123.2 280C123.2 366.598 193.402 436.8 280 436.8C366.598 436.8 436.8 366.598 436.8 280C436.8 193.402 366.598 123.2 280 123.2C193.402 123.2 123.2 193.402 123.2 280Z" fill="${color}"/></svg>`
  )}`;

const Heading2 = styled.h2`
  color: ${Color.slate};
  ${ScalingSize('font-size', 32, 56)};
  line-height: 1.14;
  letter-spacing: -0.03em;
`;

const Paragraph = styled.p`
  line-height: 1.75;
  margin: 1em 0 2em;
  color: ${Color.slate3};
`;

const KeyFigure = styled(Wrap)`
  display: grid;
  grid-template-columns: repeat(20, 1fr);
  align-items: end;
  ${ScalingSize('margin-top', 32, 112)};
  ${ScalingSize('margin-bottom', 24, 80)};
`;

const Blockquote = styled(Markdown)`
  grid-column: 3 / 16;
  width: 85%;
  margin-top: 1rem;
`;

const KeyFigureImage = styled.figure`
  grid-row: 1;
  grid-column: 1 / 21;
  z-index: 2;
  margin: 0;

  @media screen and (min-width: 769px) {
    grid-column: 1 / 15;
  }

  img {
    width: 100%;
    object-fit: cover;
  }
`;

const KeyFigureQuote = styled(Blockquote)`
  max-width: 40rem;
  grid-row: 2;
  grid-column: 1 / 21;
  z-index: 2;
  justify-self: center;
  ${ScalingSize('margin-top', 24, 40)};
  margin-bottom: 0;

  @media screen and (min-width: 769px) {
    grid-column: 1 / 16;
  }
`;

const KeyFigureFigure = styled.div`
  grid-row: 1;
  grid-column: 13 / 21;
  position: relative;
  padding-bottom: 100%;

  > div {
    position: absolute;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 0 25%;
    text-align: center;
    display: none;

    @media screen and (min-width: 769px) {
      display: flex;
    }
    
    ${Heading2},
    ${Paragraph} {
      position: relative;
      margin: 0;
      color: ${Color.iris};
    }

    ${Heading2} {
      margin: 0 0 1rem;
    }

    ${Paragraph} {
      font-size: 0.875rem;
    }

    &:before {
      content: '';
      position: absolute;
      background-image: url('${Ellipse(Color.champ4)}');
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border-radius: 999em;
    }
  }
`;
