import React from 'react';
import styled from 'styled-components';

import { StartPageHero as StartPageHeroType } from '../shared/interfaces';

import { WrapMax } from 'src/components/shared/wraps';
import { ScalingSize, Color } from '../config/variables';
import { Button, Heading1, Heading2, Excerpt } from 'src/components/shared';
interface Props {
  block: StartPageHeroType;
}

export const BlockStartPageHero = (props: Props) => (
  <Outer
    data-color={Color.champ5}
    data-circle
    data-circle-color={Color.citrus}
    data-circle-position-x={102.057291667}
    data-circle-position-y={137.8240741}
    data-circle-radius={27.2135416}
  >
    <Inner>
      <Heading1 startPage>{props.block.H1}</Heading1>
      <Row>
        <Heading2 startPage>{props.block.H2}</Heading2>
        <Excerpt startPage>{props.block.Content}</Excerpt>
      </Row>
      <Cta bg={Color.citrus5} cover direction="left" as={Button} inline to={props.block.Button.URL} text={props.block.Button.Title} icon="ArrowRight" />
    </Inner>
  </Outer>
);

const Row = styled.div`
  display: grid;
  grid-template-columns: 100%;
  grid-gap: 1em;
  max-width: 61.625em;
  justify-self: center;
  align-items: center;

  @media screen and (min-width: 860px) {
    grid-gap: 4em 2.5em;
    grid-row: 2;
    grid-template-columns: 410fr 536fr;
  }
`;

const Cta = styled.div`
  margin: 1em 0 0;
  justify-self: start;

  @media screen and (min-width: 860px) {
    justify-self: center;
    margin-top: 0;
    grid-column: 1 / 2;
  }
`;

const Outer = styled.section`
  ${ScalingSize('margin-top', 40, 88)};
  ${ScalingSize('margin-bottom', 32, 72)}
`;

const Inner = styled(WrapMax)`
  display: grid;
  grid-template-columns: 100%;
  grid-gap: 1em;

  @media screen and (min-width: 860px) {
    grid-gap: 4em;
  }
`;
