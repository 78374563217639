import React, { Component, createRef } from 'react';
import styled from 'styled-components';
import Fade from 'react-reveal/Fade';

import { BlockCenteredText as CenteredTextType } from '../shared/interfaces';

import { WrapXs } from 'src/components/shared/wraps';
import { Markdown } from 'src/components/shared'; 
import { Color } from '../config/variables';

interface Props {
  block: CenteredTextType;
}

export const BlockCenteredText = (props: Props) => (
  <Wrap data-color={Color.white}>
    <Fade bottom distance={'10%'} fraction={0.2}>
      <Markdown content={props.block.Content} />
    </Fade>
  </Wrap>
);

const Wrap = styled(WrapXs)`
  margin: 0 auto;

  @media screen and (min-width: 978px) {
    margin: 5rem auto;
  }
`;